.rdrCalendarWrapper > div.rdrMonths > div.rdrMonth {
  width: 100%;
  padding: 0;
}

.rdrDateRangePickerWrapper {
  display: flex;
  flex-direction: column;
  max-width: 850px;
  margin: 0 auto;
}

@media (min-width: 600px) {
  .rdrDateRangePickerWrapper {
    flex-direction: row;
  }
}

.rdrDefinedRangesWrapper {
  flex: 1;
  width: 100%;
}

.rdrCalendarWrapper {
  flex: 3;
}

.rdrCalendarWrapper.rdrDateRangeWrapper
  > div.rdrMonths.rdrMonthsVertical
  > div
  > div.rdrDays
  > button
  > span
  > span::after {
  background: #2328eb;
}
